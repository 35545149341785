import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Dashboard from "../../layout/dashboard";
import Meta from "../../components/global/Meta";
import axios from "axios";
import PackCardV3 from "../../components/v3/PackCardV3";
import PackCardPaid from "../../components/v3/PackCardPaid";

import styles from "./HomePage.module.scss";
import { useHistory } from "react-router-dom";

const userProfile = JSON.parse(localStorage.getItem("userProfile"));
const API_URL = process.env.REACT_APP_API_URL;

const HomePageV3 = () => {
  const history = useHistory();
  const [freePacks, setFreePacks] = useState([]);
  const [purchasedPacks, setPurchasedPacks] = useState([]);
  const [notPurchasedPacks, setNotPurchasedPacks] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/api/packs/v3`)
      .then(({ status, data }) => {
        if (status === 200) {
          setPurchasedPacks(data.data?.purchasedPacks);
          setNotPurchasedPacks(data.data?.notPurchasedPacks);
          setFreePacks(data.data?.freePacks);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleViewPack = (packId, version) => {
    if (version === 3) {
      return history.push(`/packs/${packId}`);
    }
    history.push(`/v2/dash?packId=${packId}`);
  };
  return (
    <Dashboard>
      <Meta title={`Dashboard`} />

      <div className="container-fluid my-5">
        <div className="row">
          <h2 className="mb-2 text-primary" style={{ fontWeight: "bold" }}>
            {formatGreeting()}, {userProfile?.googleName}!
          </h2>
          <p className="text-muted">
            Welcome back! All the best because #PaperPhodnaHai
          </p>
        </div>

        <div className="row">
          <div className="col-12">
            <h2>Your test series</h2>
          </div>
        </div>
        <div className={"row"}>
          {purchasedPacks?.length > 0 ? (
            <>
              {purchasedPacks?.map((pack, packIndex) => (
                <div
                  key={pack?._id}
                  className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-2"
                >
                  <div className="h-100">
                    <PackCardV3 {...pack} handleViewPack={handleViewPack} />
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="col-12">
              <div className="card shadow-none px-3 py-4 d-flex justify-content-center align-items-center">
                Looks like you haven't purchased any test packs. Explore and buy
                packs from below.
              </div>
            </div>
          )}
        </div>

        {notPurchasedPacks?.length > 0 && (
          <>
            <div className="row mb-3">
              <div className="col-12">
                <h2>Explore other test series</h2>
              </div>
            </div>
            <div className={`row`}>
              {notPurchasedPacks?.length > 0 && (
                <>
                  {notPurchasedPacks?.map((pack, packIndex) => (
                    <div
                      key={pack?._id}
                      className=" col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-4"
                    >
                      <div className="d-flex justify-content-center h-100">
                        <PackCardPaid
                          {...pack}
                          handleViewPack={handleViewPack}
                        />
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </Dashboard>
  );
};

export default HomePageV3;

const formatGreeting = () => {
  const today = new Date();
  const hour = today.getHours();
  if (hour < 12) {
    return "Good Morning";
  } else if (hour < 16) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
};

const ExamCard = styled.div`
  display: flex;
  min-width: 400px;
  padding: 24px;
  align-items: center;
  align-content: center;
  gap: 16px;
  flex-wrap: wrap;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
  border-radius: 12px;
  border: 1px solid var(--grayscale-light, #e3ebf6);
  background: #fff;
  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
`;
