import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
const HtmlToReactParser = require("html-to-react").Parser;
const htmlToReactParser = new HtmlToReactParser();

const HelloBar = () => {
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    axios.get("/api/content/alert").then((res) => {
      setAlert(res.data?.data);
    });
  }, []);

  if (!alert) return null;

  if (!alert?.isHelloBarVisible) return null;

  return (
    <AlertBar
      className="border-none rounded-none text-center d-flex justify-content-center align-items-center gap-2 p-1 py-2 flex-wrap"
      role="alert"
      {...alert}
    >
      {htmlToReactParser.parse(alert?.text)}
      {alert?.isButtonVisible && (
        <HelloButton
          {...alert}
          href={alert?.buttonLink}
          className="p-1 px-2"
          target="_blank"
        >
          {alert?.buttonText}
        </HelloButton>
      )}
    </AlertBar>
  );
};

export default HelloBar;

const AlertBar = styled.div`
  background-color: ${(props) => props.helloBarBackgroundColor};
  color: ${(props) => props.textColor};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;

  position: sticky;
  top: 0;
  z-index: 9999;
  width: 100%;
  padding: 0.5rem 1rem;
`;

const HelloButton = styled.a`
  background-color: ${(props) => props.buttonBackgroundColor}};
  color: ${(props) => props.buttonTextColor}} !important;

  border: none;
  border-radius: 8px;
  font-size: 14.4px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  line-height: 1.2;
`;
