import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Question from "./Question";
import SingleBookmark from "./SingleBookmark";
import QUESTION_TYPE_MAP from "../../utils/variables";

const QuestionView = ({
    show,
    hide,
    question,
    notebookId,
    refresh,
    onNext,
    onPrev,
    disableNext,
    disablePrev,
}) => {
    return (
        <>
            <Modal
                show={show}
                onHide={hide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="d-flex justify-content-between">
                    <div className="">
                        <Modal.Title
                            id="contained-modal-title-vcenter"
                            className="mb-0"
                        >
                            Question View
                        </Modal.Title>
                        <div className="row">
                            <div className="col-12">
                                <p className="text-muted mb-0">
                                    {QUESTION_TYPE_MAP[question?.questionId?.type]}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center gap-3">
                        <SingleBookmark
                            questionId={question?.questionId?._id}
                            notebookId={notebookId}
                            refresh={() => {
                                hide();
                                refresh();
                            }}
                        />
                        <button className="btn btn- p-0" onClick={hide}>
                            x
                        </button>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Question question={question} />
                </Modal.Body>

                <Modal.Footer>
                    {/* Create next and prev */}
                    <button
                        className="btn btn-primary"
                        disabled={disablePrev}
                        onClick={onPrev}
                    >
                        Prev
                    </button>

                    <button
                        className="btn btn-primary"
                        disabled={disableNext}
                        onClick={onNext}
                    >
                        Next
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default QuestionView;
