import React, { useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setRPack } from "../../redux/slices/packSlice";
import { setUser } from "../../redux/slices/userSlice";

const UserAuthProfile = () => {
  const router = useHistory();
  const dispatch = useDispatch();

  const doLogout = () => {
    dispatch(setRPack(null));
    localStorage.removeItem("userProfile");
    localStorage.removeItem("recentPack");
    Cookies.remove("token");
    router.push("/auth");
  };

  const location = useLocation();

  const { testId, packId } = useParams();
  const isTestPage = packId && testId;
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("t");
  const currentToken = Cookies.get("token");
  const isTokenSame = currentToken === token;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        let request;
        if (isTestPage && token && !isTokenSame) {
          dispatch(setRPack(null));
          localStorage.removeItem("userProfile");
          localStorage.removeItem("recentPack");
          request = await axios.get("/api/auth/user", {
            headers: { Authorization: `Bearer ${token}` },
          });
          Cookies.set("token", token);
        } else {
          request = await axios.get("/api/auth/user");
        }
        if (!request) {
          doLogout();
        } else {
          dispatch(setUser(request.data?.data));
        }
      } catch (error) {
        console.log("Error: ", error);
      }
    }
    fetchUser();
  }, []);
  return <div></div>;
};

export default UserAuthProfile;