import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import PreparationDetailsFormV2 from "./PreparationDetailsFormV2";

const PATHS_TO_IGNORE = [
  "/onboarding",
  "/auth",
  "/maintenance",
  "/auth/login",
  "/auth/reset/request",
  "/logout",
  "/login",
  "/tests",
];

const PreparationDetailsModal = () => {
  const [show, setShow] = useState(false);
  const history = useHistory();
  
  const user = JSON.parse(localStorage.getItem("userProfile"));
  const preparationDetailsV2Completed = user ? user.preparationDetailsV2Completed : false;

  useEffect(() => {
    const currentPath = window.location.pathname;
    const shouldIgnore = PATHS_TO_IGNORE.some(path => currentPath.startsWith(path));

    if (user && !preparationDetailsV2Completed && !shouldIgnore) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [user, preparationDetailsV2Completed]);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        <PreparationDetailsFormV2 />
      </Modal.Body>
    </Modal>
  );
};

export default PreparationDetailsModal;
